import { BrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import "./config/axios/config";
import Loader from "./layouts/loader/Loader";
import { Suspense } from "react";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import RequireAuth from "./gates/auth/RequireAuth.js";
import DeniedLogin from "./gates/auth/DeniedLogin.js";

/****Layouts*****/
const FullLayout = lazy(() => import("./layouts/FullLayout.js"));

/***** Pages ****/

const Auth = lazy(() => import("./pages/auth/Auth.js"));
const Starter = lazy(() => import("./pages/Starter.js"));
const Category = lazy(() => import("./pages/backoffice/Category.js"));
const House = lazy(() => import("./pages/backoffice/House.js"));
const Room = lazy(() => import("./pages/backoffice/Room.js"));
const Location = lazy(() => import("./pages/backoffice/Location.js"));
const Alerts = lazy(() => import("./pages/ui/Alerts"));
const Badges = lazy(() => import("./pages/ui/Badges"));
const Buttons = lazy(() => import("./pages/ui/Buttons"));
const Cards = lazy(() => import("./pages/ui/Cards"));
const Grid = lazy(() => import("./pages/ui/Grid"));
const Tables = lazy(() => import("./pages/ui/Tables"));
const Forms = lazy(() => import("./pages/ui/Forms"));
const Breadcrumbs = lazy(() => import("./pages/ui/Breadcrumbs"));
const Onboarding = lazy(() => import("./pages/backoffice/Onboarding.js"));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<span>Page non trouvé</span>} />

            <Route element={<DeniedLogin />}>
              <Route exact path={"/"} element={<Auth />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route exact path={"/onboarding"} element={<Onboarding />} />

              <Route element={<FullLayout />}>
                {/*               <Route path="/dashboard" element={<Navigate to="starter" />} />
                 */}{" "}
                <Route path="/dashboard" element={<Starter />} />
                <Route path="/rooms" element={<Room />} />
                <Route path="/houses" element={<House />} />
                <Route path="/categories" element={<Category />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/badges" element={<Badges />} />
                <Route path="/buttons" element={<Buttons />} />
                <Route path="cards" element={<Cards />} />
                <Route path="grid" element={<Grid />} />
                <Route path="table" element={<Tables />} />
                <Route path="forms" element={<Forms />} />
                <Route path="breadcrumbs" element={<Breadcrumbs />} />
                <Route path="/locataires" element={<Location />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
