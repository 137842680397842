import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Auth = lazy(() => import("../pages/auth/Auth.js"));
const Starter = lazy(() => import("../pages/Starter.js"));
const About = lazy(() => import("../pages/About.js"));
const Category = lazy(() => import("../pages/backoffice/Category.js"));
const House = lazy(() => import("../pages/backoffice/House.js"));
const Room = lazy(() => import("../pages/backoffice/Room.js"));
const Onboarding = lazy(() => import("../pages/backoffice/Onboarding.js"));
const Location = lazy(() => import("../pages/backoffice/Location.js"));
const Alerts = lazy(() => import("../pages/ui/Alerts"));
const Badges = lazy(() => import("../pages/ui/Badges"));
const Buttons = lazy(() => import("../pages/ui/Buttons"));
const Cards = lazy(() => import("../pages/ui/Cards"));
const Grid = lazy(() => import("../pages/ui/Grid"));
const Tables = lazy(() => import("../pages/ui/Tables"));
const Forms = lazy(() => import("../pages/ui/Forms"));
const Breadcrumbs = lazy(() => import("../pages/ui/Breadcrumbs"));

/*****Routes******/

export const routes = {
  starter: "starter",
};

const ThemeRoutes = [
  { path: "/", element: <Auth /> },
  { path: "/onboarding", element: <Onboarding /> },
  {
    path: "/dashboard",
    element: <FullLayout />,
    children: [
      { path: "", element: <Navigate to="starter" /> }, // Redirection par défaut vers /dashboard/starter
      { path: "starter", element: <Starter /> },
      { path: "rooms", element: <Room /> },
      { path: "houses", element: <House /> },
      { path: "categories", element: <Category /> },
      { path: "about", element: <About /> },
      { path: "alerts", element: <Alerts /> },
      { path: "badges", element: <Badges /> },
      { path: "buttons", element: <Buttons /> },
      { path: "cards", element: <Cards /> },
      { path: "grid", element: <Grid /> },
      { path: "table", element: <Tables /> },
      { path: "forms", element: <Forms /> },
      { path: "breadcrumbs", element: <Breadcrumbs /> },
      { path: "locataires", element: <Location /> },
    ],
  },
];

export default ThemeRoutes;
